<template>
    <div id="app">
      <div id="idiot-proof-menu">
        <span class="routerlink"><router-link to="/">Etusivu</router-link></span>
        <span class="routerlink"><router-link to="/vaalirikokset">Vaalirikokset 2024</router-link></span>
         <!--<span class="routerlink"><router-link to="/jaanalla-on-asiaa">Jaanalla on asiaa</router-link></span>-->
         <span class="routerlink"> <router-link to="/yleisohjelma">Yleisohjelma</router-link></span>
         <span class="routerlink"><router-link to="/paasikiven-kavoniuksen-linja">Paasikiven-<br>Kavoniuksen linja</router-link></span>
         <span class="routerlink"><router-link to="/saannot">Säännöt</router-link></span>
         <span class="routerlink"><router-link to="/hallitus">Hallitus</router-link></span>
        <!--<router-link to="/liity">Liity jäseneksi</router-link>-->
      </div>
      <Reveal
      :closeOnNavigation="true"
      noOverlay
      >
        <router-link to="/">Etusivu</router-link>
        <router-link to="/vaalirikokset">Vaalirikokset 2024</router-link>
        <router-link to="/jaanalla-on-asiaa">Jaanalla on asiaa</router-link>
        <router-link to="/yleisohjelma">Yleisohjelma</router-link>
        <router-link to="/paasikiven-kavoniuksen-linja">Paasikiven-<br>Kavoniuksen linja</router-link>
        <router-link to="/saannot">Säännöt</router-link>
        <router-link to="/hallitus">Hallitus</router-link>
        <!--<router-link to="/liity">Liity jäseneksi</router-link>-->
      </Reveal>
      <main id="page-wrap">
    <router-view/>
       <footer class="footer">
      <div class="content has-text-centered has-text-white">
             <img src="@/assets/pallologo.png" style="width: 150px;" alt="Totuuspuolue pallologo"><br><br>
                    Totuuspuolue ry<br>
                3269142-4<br><br>
Myllärinkatu 4<br>
08100 LOHJA<br><br>
<i class="fa-brands fa-telegram"></i>
      </div>
    </footer>
      </main>
  </div>
</template>
<script>
import { Reveal } from 'vue-burger-menu'  
export default {
    components: {
        Reveal // Register your component
    },
    name: 'App',
    metaInfo: {
        // if no subcomponents specify a metaInfo.title, this title will be used
        title: 'Totuuspuolue ry',
        // all titles will be injected into this template
        titleTemplate: '%s | Totuuspuolue ry'
    }
}
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
}
#idiot-proof-menu {
  width:100%;
  background-color: yellow;
  height: 50px;
  display: table;
  table-layout: fixed;
}
.routerlink {
  display: table-cell;
  text-align: center;
  font-size: 1.5rem;
  padding-top: 0.5rem;
}
.bm-burger-button {
      position: fixed;
      width: 36px;
      height: 30px;
      left: 36px;
      top: 36px;
      cursor: pointer;
    
    }
    .bm-burger-bars {
      background-color: yellow !important;
      
    }
    .line-style {
      position: absolute;
      height: 20%;
      left: 0;
      right: 0;
        text-shadow: 1px 1px 1px #000, 3px 3px 5px rgb(0, 0, 3);

    }
    .cross-style {
      position: absolute;
      top: 20px !important;
      right: 20px !important;
      cursor: pointer;
    }
    .bm-cross {
      background: #000 !important;;
    }
    .bm-cross-button {
      height: 40px !important;
      width: 40px !important;
    }
    .bm-menu {
      height: 100%; /* 100% Full-height */
      width: 0; /* 0 width - change this with JavaScript */
      position: fixed; /* Stay in place */
      z-index: 1000; /* Stay on top */
      top: 0;
      left: 0;
      background-color: rgba(255, 255, 0, 1)  !important;; /* Black*/
      overflow-x: hidden; /* Disable horizontal scroll */
      padding-top: 60px; /* Place content 60px from the top */
      transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
      text-align: left;
    }
     .bm-menu a:visited {
       color:#000 !important;
     }

      .bm-menu a:hover {
       color:#000 !important;
       font-weight: bold !important;
     }

    .bm-overlay {
      background: rgba(0, 0, 0, 0.3);
       background: none;
    }
    .bm-item-list {
      color: #000;
      margin-left: 10%;
      font-size: 20px;
    }
    .bm-item-list > * {
      display: flex;
      text-decoration: none;
      padding: 0.7em;
    }
    .bm-item-list > * > span {
      margin-left: 10px;
      font-weight: 700;
      color: white;
    }



.footer {
  color: white;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 10px solid yellow;
  background: url(assets/footer-tausta-totuuspuolue-jaana-kavonius.jpg);

}

footer strong {
  color: turquoise;
}

footer a {
  color: turquoise;
}

     footer h1{
      margin-bottom: 55px !important;
      color: #fff !important;
      }
     footer .content ul{
      padding: 0;
      margin: 0;
      }
     footer .content ul li{
      list-style-type: none;
      text-align: center;
      padding: 5px;
      }
     footer .content ul li a{
      color: #fff;
      font-size: 18px;
      }
     footer .content ul li a:hover{
      color: #ddd;
      }
    footer  h6{
      text-align: center;
      color: #fff !important;
      font-size: 30px !important;
      margin-bottom: 25px !important;
      }
    footer  .is-success{
      margin-top: 15px;
      }
    footer  .fa{
      padding-right: 10px;
      font-size: 24px;
      cursor: pointer;
      }
     footer .control.has-icons-left .icon.is-left{
      left: 5px;
      }
    footer  p{
      font-size: 18px;
      }
     footer .is-success:hover{
      background: #0f8438 !important;
      }
      @media(max-width: 768px){
     footer .bottom p,.has-text-right{
      text-align: center !important;
      }
      }

      @media screen and (max-width: 1024px) {
        #idiot-proof-menu {
          display: none !important;
        }
        .container {
          margin: 2rem;
        }
        .quotetitle {
          font-size: 1.6rem !important;
        }
           #alku {
     padding: 0px, 0px !important;
   }
}
 @media screen and (min-width: 1024px) {
        .bm-menu, .bm-burger-button {
         display:none !important;
        }
 }
</style>