import Vue from 'vue'
import VueRouter from 'vue-router'
import Etusivu from '../views/Etusivu.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Etusivu',
    component: Etusivu
  },
  {
    path: '/saannot',
    name: 'Rules',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Saannot" */ '../views/Saannot.vue')
  },
  {
    path: '/vaalirikokset',
    name: 'Rules',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Saannot" */ '../views/Vaalit.vue')
  },
  {
    path: '/hallitus',
    name: 'Hallitus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Hallitus" */ '../views/Hallitus.vue')
  },
  {
    path: '/liity',
    name: 'Liity',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Liity" */ '../views/Liity.vue')
  },
  {
    path: '/yleisohjelma',
    name: 'Yleisohjelma',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Yleisohjelma" */ '../views/Yleisohjelma.vue')
  },
  {
    path: '/paasikiven-kavoniuksen-linja',
    name: 'Paasikivi',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Yleisohjelma" */ '../views/Paasikivi.vue')
  },
  {
    path: '/joa-disabled',
    name: 'Jaanallaonasiaa',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/Jaanallaonasiaa.vue')
  },
  {
    path: '/joa-disabled/osa-1',
    name: 'Osa001',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa001.vue')
  },
  {
    path: '/joa-disabled/osa-2',
    name: 'Osa002',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa002.vue')
  },
  {
    path: '/joa-disabled/osa-3',
    name: 'Osa003',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa003.vue')
  },
  {
    path: '/joa-disabled/osa-4',
    name: 'Osa004',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa004.vue')
  },
  {
    path: '/joa-disabled/osa-5',
    name: 'Osa005',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa005.vue')
  },
  {
    path: '/joa-disabled/osa-6',
    name: 'Osa006',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa006.vue')
  },
  {
    path: '/joa-disabled/osa-7',
    name: 'Osa007',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa007.vue')
  },
  {
    path: '/joa-disabled/osa-8',
    name: 'Osa008',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa008.vue')
  },
  {
    path: '/joa-disabled/osa-9',
    name: 'Osa009',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa009.vue')
  },
  {
    path: '/joa-disabled/osa-10',
    name: 'Osa010',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa010.vue')
  },
  {
    path: '/joa-disabled/osa-11',
    name: 'Osa011',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa011.vue')
  },
  {
    path: '/joa-disabled/osa-12',
    name: 'Osa012',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa012.vue')
  },
  {
    path: '/joa-disabled/osa-13',
    name: 'Osa013',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa013.vue')
  },
  {
    path: '/joa-disabled/osa-14',
    name: 'Osa014',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa014.vue')
  },
  {
    path: '/joa-disabled/osa-15',
    name: 'Osa015',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa015.vue')
  },
  {
    path: '/joa-disabled/osa-16',
    name: 'Osa016',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "Jaanallaonasiaa" */ '../views/videot/Osa016.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
