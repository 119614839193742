<template>
  <div id="etusivu">
    <section id="parallax-1" class="hero is-large">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-9">
              <img alt="Totuuspuolueen logo" src="../assets/logo.png">
              <br>

              <br>
              <h2 class="subtitle quotetitle">Paljastaa poliitikkojen ja virkamiesten valheet!</h2>
              <!--<h2 class="subtitle quotetitle">”Kaiken viisauden alku on tosiasiain tunnustaminen.”<br>-Paasikivi ja Jaana Kavonius Totuuspuolueen pj.</h2>-->
              <a target="_blank" href="https://files.totuuspuolue.net/ja%CC%88senhakemus.pdf" class="button is-white is-inverted">Jäsenhakemus &ensp;<i class="fad fa-chevron-right"></i></a>
<br>
              <br>
              <!--
              <a data-v-71d8eb4f="" target="_blank" href="/kannattajakortti.pdf"><button data-v-71d8eb4f="" type="button" is-large="" class="button" style="background-color: yellow; font-weight: bold; font-size: 1.2rem; padding: 2rem;"><span>Jaana Kavonius presidentiksi<br />Lataa kannattajakortti</span></button></a><br><br>
              <div>
                <strong>Postita täytetyt ja allekirjoitetut kannatuskortit osoitteeseen:<br>
                Totuuspuolue<br>
Myllärinkatu 4, 08100 Lohja</strong>
              </div><br><br> -->
              <!-- AddToAny BEGIN -->
              <div style="width: 100%; display: flex; justify-content: center;">
                <div class="a2a_kit a2a_kit_size_32 a2a_default_style" style="margin: auto">
                  <a class="a2a_dd" href="https://www.addtoany.com/share"></a>
                  <a class="a2a_button_facebook"></a>
                  <a class="a2a_button_twitter"></a>
                  <a class="a2a_button_telegram"></a>
                  <a class="a2a_button_facebook_messenger"></a>
                  <a class="a2a_button_whatsapp"></a>
                  <a class="a2a_button_mewe"></a>
                  <a class="a2a_button_vk"></a>
                  <a class="a2a_button_email"></a>
                  </div>
                </div>
              <!-- AddToAny END -->
              <br>
              <br>
              <br>
              <br>
              <br>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="parallax-2" class="hero is-large ">
      <div id="alku" class="hero-body">
        <div class="container">
          <div style="width:100%">
           
<div id="rumble_v44idwc"></div>
<!--
            <div style="position: relative; padding-top: 56.25%;">
  <iframe
    src="https://customer-o60iosywtbiybcpf.cloudflarestream.com/e720388a86b3f2db767c22250d2dde1f/iframe?poster=https%3A%2F%2Fcustomer-o60iosywtbiybcpf.cloudflarestream.com%2Fe720388a86b3f2db767c22250d2dde1f%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600"
    style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;"
    allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
    allowfullscreen="true"
  ></iframe>
</div>-->
            <!--<img style="float:left; width: 26%;" src="/salemeemi.jpg" />
          <img style="float:left; width: 66%;" src="/jaana_presidentiksi.jpeg" />-->
         </div>
          <!--
          <div style="position: relative; padding-top: 56.25%;"><iframe src="https://iframe.videodelivery.net/5e0b61207f4237175333ea6acd993e8a?poster=https%3A%2F%2Fvideodelivery.net%2F5e0b61207f4237175333ea6acd993e8a%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600" style="border: none; position: absolute; top: 0; left: 0; height: 100%; width: 100%;" allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;" allowfullscreen="true"></iframe></div>
          -->
          <!--
          <div class="columns">
            <div class="column is-12">
                           <h1 class="title is-12 ">JAANA KAVONIUS JA TOTUUSPUOLUE PAASIKIVEN LINJALLA</h1>
                           <br>
                           <h2 class="subtitle">Alla on 10 tosiasiaa Suomen pelastamiseksi totaaliselta tuholta nykyisessä uhkaavassa tilanteessa:</h2>
              <div class="columns">
                <div class="column is-6">
 <div class="has-text-left">
                  <ul class="asialista">
                    <li><span class="asianro">&nbsp;1 §</span> Natoon hakeutuminen tarkoittaa Suomelle riskiä joutua samanlaisen tai vielä pahemman hyökkäyksen kohteeksi kuin Ukraina on nyt joutunut.</li>
                    <li><span class="asianro">&nbsp;2 §</span> Naton jäsenten keskinäinen apu on sopimus eikä luonnonlaki. Sopimus voidaan aina rikkoa.</li>
                    <li><span class="asianro">&nbsp;3 §</span> Venäjä ei ole enää globaalin vallankumouksen aatteen Neuvostoliitto, joka vie omaa ideologiaansa ja yhteiskuntajärjestystään ulkomaille, vaan nationalistinen valtio, jossa lähtökohta on kunkin kansan ja kulttuurin elämä omilla perinteillä.</li>
                    <li><span class="asianro">&nbsp;4 §</span> Venäjä ei ole vaatinut sen normien asettamista Suomen perustuslain edelle kuten EU on tehnyt.</li>
                    <li><span class="asianro">&nbsp;5 §</span> Venäjä ei vaadi suomalaisia sotilaita sotiin Suomen rajojen ulkopuolelle kuten NATO vaatisi.</li>
                     <li><span class="asianro">&nbsp;6 §</span> Venäjän Suomeen ja muihin naapureihin nyt kohdistamat turvallisuusvaatimukset ovat aivan vastaavat, joita isänmaalliset presidenttimme Mannerheim ja Paasikivi etenkin pitivät täysin oikeutettuina.</li>
                    
                    </ul>
                  </div>
                </div>
                <div class="column is-6">
     
                                  <div class="has-text-left">
                  <ul class="asialista">
                   <li><span class="asianro">&nbsp;7 §</span> Hyökkäystä Ukrainaan ei ole syytä hyväksyä, mutta Putinin ja Venäjän demonisoimisen sijaan pitää tunnustaa, että monet tahot ovat provosoineet tätä hyökkäystä ja nimenomaan juuri tähän ajankohtaan. Se on tehty kasvaneiden kuolinlukujen paljastaman rokotekansanmurhan peittelemiseksi, ja tätähän länsimainen media peittelee.</li>
                    <li><span class="asianro">&nbsp;8 §</span> Suomen rooli tulisi olla sovittelijan rooli, ei puolueellisen tuomarin rooli, mikä nyt on Niinistön, Marinin, Halla-ahon ja muun ulkopoliittisen johdon linja. Niinistön ylimielinen ilmoitus siitä, että hänellä ei ole mitään puhuttavaa Putinin kanssa, on Suomen ulkopolitiikan historian idioottimaisimpia tekoja.</li>
                    <li><span class="asianro">&nbsp;9 §</span> Suomea ei pelasta Nato, vaan Paasikiven linja, jossa aidosti itsenäinen Suomi luo hyvät suhteet Venäjään. Se ei saa pitää sisällään Kekkosen ajan suomettuneisuutta – eikä siihen ole mitään tarvettakaan nykyisen Venäjän kanssa. Paasikiven linjalla on pärjätty ennenkin, mutta Niinistön, Marinin ja Halla-Ahon Venäjää ja Putinia demonisoiva ja sinne suuntaan vittuileva natohakuinen linja tarkoittaa Paasikiven linjan hylkäämistä ja maksimaalisen sodanuhkan tahallista aiheuttamista Suomelle.</li>
                    <li><span class="asianro">10 §</span> Jaana ja Totuuspuolue ovat Paasikiven linjalla. Parasta työtä Suomen pelastamiseksi on lopettaa valtiojohtomme vittuilu Venäjän suuntaan, pitää Paasikiven linja ja tukea Jaanaa presidentiksi, jotta Paasikiven linja on järkevän Venäjä-suhteen ytimessä jatkossa.</li>
                  </ul>
                  </div>
                </div>
            
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </section>
    <!--
    <section id="parallax-3" class="hero is-large ">
      <div class="hero-body">
        <div class="container">
          <div class="columns">
            <div class="column is-6 is-offset-6">
              <h1 class="title is-1 ">Amet Consectetur</h1>
              <hr class="content-divider">
              <h2 class="subtitle">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iure minus nam necessitatibus neque in perferendis eveniet dolorum assumenda dolores accusamus.</h2>
              <a href="#" class="button is-white is-inverted">Next&ensp;<i class="fad fa-chevron-right"></i></a>
            </div>
          </div>
        </div>
      </div>
    </section>
    -->
  </div>
</template>
<script>
export default {
   mounted() {
        window.Rumble("play", {"video":"v44idwc","div":"rumble_v44idwc"});
   }
}

</script>
<style scoped>
.asialista {
 color: #000;
  font-size: 1.4rem;
}

.asialista li {
  padding: 30px;
}

.asianro {
  margin-right: 10px;
  margin-bottom:10px;
  width:50px;
  height:50px;
  display:inline-flex;
  align-items:center;
  justify-content: center;
  font-size:16px;
  background-color:yellow;
  border-radius:50%;
  border-color:#000;
  border-style: solid;
  border-width: 3px;
  color:#000;
  font-weight: bold;
}
.va {
  display: flex;
  align-items: center;
}



.title {
  color: white;
  font-weight: 800;
  font-size: 4rem;
  font-family: 'Nunito Sans', sans-serif;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px rgb(0, 0, 3);
}

.subtitle {
  color: white;
  font-size: 1.6rem;
  text-shadow: 1px 1px 1px #000, 3px 3px 5px rgb(0, 0, 3);
}

hr.content-divider {
  max-width: 75px;
  height: 5px;
}

#parallax-1 {

  background: url(../assets/pilvitausta-suomen-lippu-totuuspuolue-jaana-kavonius.jpg);

  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#parallax-1 hr {
  background: yellow;
}

#parallax-2 {
  background: url(../assets/pilvitausta-saarella-totuuspuolue-jaana-kavonius.jpg);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 10px solid yellow;
}

#parallax-2 hr {
  background: yellow;
}

#parallax-3 {
  background: url(https://source.unsplash.com/featured/?city);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-top: 10px solid hotpink;
}

#parallax-3 hr {
  background: hotpink;
}

.label {
  color: white;
}

input {
  background: transparent !important;
  border: 1px solid turquoise !important;
  color: white !important;
}

input::placeholder {
  color: white !important;
}

.quotetitle {
  color: yellow;
  font-size: 2.5rem;
}

 @media screen and (max-width: 1024px) {

   .title {
     font-size: 2rem;
   }
   .asialista {
     padding: 0px;
     text-align: center;
     font-size: 1.2rem;
   }

   .asialista span {
     margin: auto;
     display: block;
     padding-left: 2px;
     padding-top: 10px;
     
   }
   #parallax-1 {

    background: url(../assets/lipputausta-mobile.jpg);
      background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
   }
#parallax-2 {
  background: url(../assets/pilvitausta-mobile.jpg);
    background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
 }
    #etusivu {
     padding: 0px, 0px !important;
   }

    #alku {
     padding: 0px, 0px !important;
   }
 }
</style>
